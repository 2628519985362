
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;

}

:root {
  --mainColor: #29335c;
  --mainColorLight: #1d2852;
  --secondaryColor:#db2839;
  --textColor:#eee;
}

header{
  display: flex;
  align-items: center;
  justify-content:space-between;
  height: 120px;
  padding: 0 2rem;
  background-color: var(--mainColorLight);
  color: var(--textColor);

}


nav ul {
  display: flex;
  list-style: none;
  gap: 6.5rem; /* Space between links */
  font-size: 30px;
  justify-content: center; /* Centers the items horizontally */
  align-items: center; /* Aligns the items vertically in the middle */
  text-align: center;
  width: 100%; /* Ensures the UL takes up the full width of the navbar */
  height: 100%; /* Ensures the UL takes full height for proper vertical alignment */
}

nav ul li {
  padding-top: 50px;
  display: flex;
  align-items: center; /* Centers text vertically in the li */
}



nav ul {
margin: 0 2rem;
color: var(--textColor);
text-decoration: none;

}

nav ul:hover {
  color: var(--textColor);
}

header .nav-btn {
padding: 5px;
cursor: pointer;
background-color: transparent; 
border: none;
outline: none;
visibility: hidden;
color: var(--textColor);
opacity: 0;
font-size: 2.0rem;
}
@media only screen and (max-width: 1024px){
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }



  header nav {
      position: fixed;
      top: 0;
      right: 0; /* Position it on the right */
      height: 55vh; /* Full height */
      width: auto; /* Adjust the width to fit the content */
      display: flex;
      flex-direction: column; /* Stack items vertically */
      justify-content:center;
      align-items: flex-end;
      gap: 1.5rem;
      background-color: var(--mainColor);
      transform: translateX(100%);
      z-index: 1000;
  }

  nav ul{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 20px;
    gap:1px;
    padding-top: 30px;
 
   }

  header .responsive_nav {
    transform: none;

  }

  nav  .nav-close-btn {
  position: absolute;
  top: 2rem;
  right: 2rem;

  }

  nav link{
    font-size: 2.0rem;
  }
}